<template>
  <van-popup v-model="isShow" :close-on-click-overlay="false" duration="0">
    <NavBar :bool="true" @navCallback="navCallback" class="nav-bar">
      <div slot="title">个人介绍</div>
    </NavBar>
    <div class="input-content">
      <van-field
        v-model="content"
        rows="2"
        autosize
        type="textarea"
        maxlength="240"
        placeholder="请输入留言"
        show-word-limit
        class="field-textarea"
      />
    </div>
    <div class="button">
      <van-button
        round
        block
        color="linear-gradient(to right, #FA6A5A, #FB604E,#F83820)"
        @click="saveBtn"
      >
        保存
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import { setSynopsis } from "@/api/index";
import { getMemberInfo } from "@/api/member";
import NavBar from "@/layout/nav-bar/nav-bar";
import { Toast } from "vant";
export default {
  name: "self-introduction",
  components: {
    NavBar,
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      content: "",
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      if (newVal) {
        this.getMemberInfo();
      }
    },
  },
  methods: {
    navCallback() {
      this.$emit("closePopup");
    },
    saveBtn() {
      this.setSynopsis();
    },
    async setSynopsis() {
      const res = await setSynopsis({ describe: this.content });
      console.log(res);
      if (res.code === 1) {
        Toast.success("保存成功");
        this.$emit("closePopup");
      } else {
        Toast.fail(res.msg);
      }
    },
    async getMemberInfo() {
      const res = await getMemberInfo();
      console.log(res);
      this.content = res.data.describe;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup--center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
}
.van-popup {
  background-color: #f5f5f5;
}
.nav-bar {
  background-color: #fff;
}
.input-content {
  height: 218px;
  margin: 64px 12px 26px 12px;
  background-color: #fff;
  border-radius: 13px;
  padding: 14px 16px;
  overflow: hidden;
}
/deep/.van-field__control {
  height: 170px !important;
}
.button {
  padding: 0 12px;
}
</style>