<template>
  <div>
    <NavBar class="nav-nar">
      <div slot="title">信息修改</div>
    </NavBar>
    <div class="back-color">
      <div class="portrait-layout">
        <div class="head-portrait" @click="editAvatar">
          <div class="img-box">
            <img :src="head_img || headImgUrl" alt="" class="head-img" />
          </div>
          <div class="operation">
            <span>更换</span>
            <van-icon name="arrow" color="#C6C6C6" />
          </div>
        </div>
        <div class="name-operation">
          <van-cell-group>
            <van-field
              v-model="nickname"
              border
              maxlength="12"
              label="昵称"
              placeholder="起个名字吧"
              @change="updateNickname"
            />
          </van-cell-group>
        </div>
        <div class="gender-operation">
          <div class="title-sex">性别</div>
          <van-radio-group v-model="sex" direction="horizontal">
            <van-radio value="2" name="2" checked-color="#ee0a24">
              女
            </van-radio>
            <van-radio value="1" name="1" checked-color="#ee0a24">
              男
            </van-radio>
            <van-radio value="0" name="0" checked-color="#ee0a24">
              保密
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="mobile-and-password">
      <div class="padding-layout">
        <van-cell is-link @click="introduce">个人介绍</van-cell>
        <van-cell v-if="phone == ''" is-link @click="bindMobile">
          绑定手机号
        </van-cell>
        <van-cell v-else is-link @click="bindMobile">
          <div class="mobile-flex">
            <div>更换手机号</div>
            <div class="mobile" ref="mobile">{{ phone }}</div>
          </div>
        </van-cell>
        <van-cell is-link @click="updatePass">支付密码</van-cell>
      </div>
    </div>
    <div class="button-box" v-if="isSecede">
      <van-button block @click="onExit"> 退出登录 </van-button>
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @select="onSelect"
    />
    <van-uploader
      id="uploaderImg"
      v-show="false"
      v-model="fileList"
      multiple
      :after-read="commonBase64"
      :max-count="3"
    />
    <PromptPopup
      :showPopup="showPopup"
      :msg="msg"
      @popupFn="popupFn"
    ></PromptPopup>
    <SelfIntroduction
      :isShow="isIntroduce"
      @closePopup="closePopup"
    ></SelfIntroduction>
    <van-loading v-show="isLoading" size="24px">加载中...</van-loading>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import SelfIntroduction from "./components/self-introduction/self-introduction";
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import { getMemberInfo, updateMemberInfo } from "@/api/member";
import { Toast } from "vant";
import tools from "@/common/js/tools";
import { commonBase64 } from "@/api/common";

export default {
  name: "alter-user-info",
  components: {
    NavBar,
    PromptPopup,
    SelfIntroduction,
  },
  data() {
    return {
      isLoading: false,
      showPopup: false,
      isSecede: true,
      msg: "您是否确认退出登录？",
      sex: this.$route.query.sex === undefined ? "0" : this.$route.query.sex,
      head_img:
        this.$route.query.head_img == undefined
          ? ""
          : this.$route.query.head_img,
      nickname:
        this.$route.query.nickname == undefined
          ? ""
          : this.$route.query.nickname,
      phone:
        this.$route.query.phone == undefined ? "" : this.$route.query.phone,
      phone_num:
        this.$route.query.phone_num == undefined
          ? ""
          : this.$route.query.phone_num,
      colorOrFont: {
        textSize: "22px",
      },
      show: false,
      actions: [{ name: "拍照" }, { name: "从手机里面选择" }],
      headImgUrl: require("@/assets/common/personal_centerhead_portrait.png"),
      isWx: false,
      fileList: [],
      isIntroduce: false, //个人介绍
    };
  },
  watch: {
    sex: async function () {
      const res = await updateMemberInfo({ sex: this.sex });
      console.log(res);
      if (res.code * 1 === 1) {
        Toast.success(res.msg);
      } else {
        Toast.fail(res.msg);
      }
    },
  },
  async mounted() {
    if (window.location.host === "wap.jhcs888.com") {
      this.isSecede = false;
    }
    const res = await getMemberInfo();
    if (res.code * 1 === 1) {
      this.sex = res.data.sex + "";
      this.head_img = res.data.head_img;
      this.nickname = res.data.nickname;
      this.phone = res.data.phone;
      this.phone_num = res.data.phone_num;
    }
    this.isWx = tools.isWx();
    if (this.isWx) {
      let wxRet = await tools.wxInitialize(["chooseImage", "getLocalImgData"]);
      this.actions = [{ name: "拍照" }, { name: "从手机里面选择" }];
    } else {
      this.actions = [{ name: "从手机里面选择" }];
    }
  },
  methods: {
    introduce() {
      this.isIntroduce = true;
    },
    closePopup() {
      //个人介绍回调
      this.isIntroduce = false;
    },
    onExit() {
      this.showPopup = true;
    },
    popupFn(bool) {
      // 弹出提示框
      if (bool) {
        this.$store.commit("setRemove");
        let _that = this;
        Toast.success("退出成功");
        setTimeout(function () {
          _that.$router.push("/login-page");
        }, 1500);
      }
      this.showPopup = false;
    },
    updateNickname() {
      if (this.nickname != "") {
        updateMemberInfo({ nickname: this.nickname });
      } else {
        Toast.fail("请输入昵称");
        return false;
      }
    },
    bindMobile() {
      this.$router.push({
        path: "/bind-mobile",
        query: { phone: this.phone, phone_num: this.phone_num },
      });
    },
    updatePass() {
      // if (this.phone_num == "") {
      //   Toast.fail("请先绑定手机号码");
      //   return false;
      // }
      this.$router.push({
        path: "/alter-password",
        query: { phone: this.phone, phone_num: this.phone_num },
      });
    },
    // 唤出弹出层
    editAvatar() {
      if (this.isWx) {
        let _that = this;
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
          success: function (res) {
            var localIds = res.localIds[0]; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            wx.getLocalImgData({
              localId: localIds, // 图片的localID
              success: async function (res) {
                // var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                const ret = await commonBase64({ image: res.localData });
                if (ret.code * 1 === 1) {
                  _that.head_img = ret.data.url;
                  updateMemberInfo({ head_img: _that.head_img });
                }
              },
            });
          },
        });
      } else {
        this.show = true;
      }
    },
    //取消弹出层
    onCancel() {
      this.show = false;
    },
    onSelect(action, index) {
      if (this.isWx) {
        let sourceType = index * 1 === 0 ? "album" : "camera";
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: [sourceType], // 可以指定来源是相册还是相机，默认二者都有
          success: function (res) {
            var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          },
        });
      } else {
        document.getElementById("uploaderImg").click();
      }
    },

    async commonBase64(file) {
      //图片上传
      this.isLoading = true;
      file.status = "uploading";
      file.message = "上传中...";
      this.head_img = file.content;
      const ret = await commonBase64({ image: file.content });
      if (ret.code * 1 == 1) {
        file.status = "";
        file.message = "";
        file.url = ret.data.url;
        this.head_img = ret.data.url;
        updateMemberInfo({ head_img: this.head_img });
      } else {
        file.status = "failed";
        file.message = "上传失败";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-nar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

// 布局
.back-color {
  padding-top: 58px;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
}

.mobile-and-password {
  background-color: #fff;
  margin-top: 12px;

  .padding-layout {
    padding: 0 12px;
  }
}

.portrait-layout {
  padding: 0 12px;
}

// 头像栏样式
.head-portrait {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  // border-bottom: 1px solid #ededed;
  .img-box {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    overflow: hidden;

    .head-img {
      display: block;
      width: 100%;
    }
  }

  .operation {
    display: flex;
    align-items: center;
    font-size: 17px;
  }
}

// 昵称栏
/deep/ .van-cell {
  padding: 14px 0;
  .van-cell__title {
    span {
      font-size: 17px;
      color: #000;
    }
  }
}

//性别栏
.gender-operation {
  display: flex;
  align-items: center;
  padding: 14px 0;
  width: 100%;
  .title-sex {
    font-size: 17px;
  }
}

.van-radio-group {
  margin-left: 66px;
}

.van-radio--horizontal {
  margin-right: 30px;
}

// 手机更改和支付密码
.van-cell__value {
  font-size: 16px;
}

.mobile-flex {
  display: flex;
  justify-content: space-between;
  font-size: 17px;

  .mobile {
    font-weight: normal;
    color: #6f6f6f;
  }
}
/deep/.van-field__control {
  font-size: 16px;
}
/deep/.van-radio__label {
  font-size: 16px;
}
.button-box {
  padding: 12px 0;
}
.van-button--default {
  border: none;
}
</style>
